<template>
  <div title="" v-show="show" append-to-body class="exportDialog">
    <div class="dialog" v-show="show">
      <div class="mask" @click="cancelDialog"></div>
      <div class="exportContent">
        <div class="top">
          <span>{{ $t("historyDetail.txt3") }}</span>
          <img src="@/images/hzHidden.png" alt="" @click="cancelDialog"/>
        </div>
        <div class="content">
          <div
            class="pdfBtn btn"
            @click="selectExportType(2)"
          >
            <span>{{ $t("historyDetail.txt40") }}</span>
          </div>
          <div
            class="jpgBtn btn"
            @click="selectExportType(1)"
          >
            <span>{{ $t("historyDetail.txt39") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "exportDialog",
  props: ["dialogVisible"],
  data() {
    return {
      show: this.dialogVisible,
      selectType: 0,
    };
  },
  watch: {
    dialogVisible: {
      //监听是否显示弹框
      handler(newVal, oldVal) {
        this.show = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    cancelDialog() {
      this.$emit("cancelDialog", false);
    },
    selectExportType(val) {
      if (val == 0) {
        return;
      }
      this.$emit("confirmDialog", true, val);
    },
  },
};
</script>
<style lang="less">
@import "./exportDialog.less";
</style>