<template>
  <div class="historyContiner">
    <router-view v-if="testIndex"></router-view>
    <router-view v-if="thDetail"></router-view>
    <router-view v-if="thDownload"></router-view>

    <div v-if="!thDetail && !thDownload && !testIndex">
      <HistoryList></HistoryList>
    </div>
  </div>
</template>

<script>
import HistoryList from "./HistoryList/HistoryList";
export default {
  name: "History",
  components: {
    HistoryList,
  },
  data() {
    return {
      //查看详情
      clothDeatil: {},
      testIndex: false,
      thDetail: false,
      thDownload: false,
    };
  },
  computed: {},
  methods: {},
  watch: {
    $route: {
      handler(newVal, oldVal) {
        if (newVal.path.includes("testIndex")) {
          this.thDetail = false;
          this.thDownload = false;
          this.testIndex = true;
        } else if (newVal.path.includes("thhistoryDetail")) {
          this.thDetail = true;
          this.thDownload = false;
          this.testIndex = false;
        } else if (newVal.path.includes("thdownloadpage")) {
          this.thDetail = false;
          this.thDownload = true;
          this.testIndex = false;
        } else {
          this.thDetail = false;
          this.thDownload = false;
          this.testIndex = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // this.showDetail=this.$route.params.flag
  },
  // beforeRouteLeave(to, from, next) {
  //   to.meta.keepAlive = true;
  //   next();
  // },
};
</script>

<style scoped lang="less">
@import "./index.less";
</style>