<template>
  <div class="listContiner">
    <div class="weavingHistory">
      <div class="title">{{ $t("historyList.txt1") }}</div>
      <div class="con">
        <!-- 品号 -->
        <div class="contentSearchInput">
          <label for="" class="txt">{{ $t("historyList.txt9") }}:</label>
          <el-input v-model="productCode" :placeholder="$t('historyList.txt2')" @change="productCodeChange"
            maxlength="30"></el-input>
        </div>
        <!-- 品名 -->
        <div class="contentSearchInput">
          <label for="" class="txt">{{ $t("historyList.txt10") }}:</label>
          <el-input v-model="productName" :placeholder="$t('historyList.txt2')" @change="productNameChange"
            maxlength="30"></el-input>
        </div>
        <!-- 日期 -->
        <div class="contentSearchInput maginNum">
          <label for="" class="txt dateLabel">{{ $t("historyList.txt11") }}:</label>
          <el-date-picker v-model="dateValue" type="date" :placeholder="$t('historyList.txt3')"
            :picker-options="pickerOptions" @change="datapickerChange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
            :clearable="false">
          </el-date-picker>
        </div>
        <!-- 设备 -->
        <div class="contentSearchButton">
          <div class="btn" @click="reset">{{ $t("historyList.txt4") }}</div>
          <div class="search btn" @click="search">
            {{ $t("historyList.txt5") }}
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title">{{ $t("historyList.txt6") }}</div>
      <div class="tableList">
        <div class="tableHead">
          <div v-for="(item, index) in tableHead" :key="index" :class="`item item${index + 1}`">
            {{ item }}
          </div>
        </div>
        <div class="table">
          <div class="tableCon" v-for="(item, index) in tableData" :key="index">
            <div class="item item1">{{ index + 1 }}</div>
            <div class="item item2">
              <div class="ImgCon">
                <el-image @click="LookPic(item)" v-show="item.renderStatus == '2'" style="width:98%;height:98%"
                  :src="item.pictureList.thumbImg" fit="contain"></el-image>
                <img src="@/images/waitForrender.png" alt="" class="waitForrender" v-show="item.renderStatus == '1'" />
                <img src="@/newImg/errRender.png" alt="" class="errImg" v-show="item.renderStatus == '3'" />
              </div>
            </div>
            <div class="item item3" @click="lookDetail(item)">
              {{ item.productCode }}
            </div>
            <div class="item item4">{{ item.productName }}</div>
            <div class="item item5">{{ item.createTime }}</div>
            <div class="item item6">
              <span class="renderStatusText Ongoing" v-show="item.renderStatus == '1'">{{ $t("historyList.txt27")
              }}</span>
              <span class="renderStatusText Finished" v-show="item.renderStatus == '2'">{{ $t("historyList.txt28")
              }}</span>
              <span v-show="item.renderStatus == '3'" class="renderStatusText Failed">{{ $t("historyList.txt29") }}</span>
              <img class="renderStatusImg1" src="@/images/entryLoading.png" alt="" v-show="item.renderStatus == '1'" />

              <img class="renderStatusImg1" src="@/images/errBtn.png" alt="" v-show="item.renderStatus == '3'" />

              <img class="renderStatusImg2" src="@/images/entrySuccess.png" alt="" v-show="item.renderStatus == '2'" />
            </div>
            <div class="item item7">
              <div class="completed">
                <div @click="lookDetail(item)" :style="item.renderStatus != '2' ? 'color:#D0D1D1' : ''" :class="$store.state.index.languageType == 'zh'
                  ? 'btn'
                  : 'btn enType'
                  ">
                  {{ $t("historyList.txt16") }}
                </div>
                <div :class="$store.state.index.languageType == 'zh'
                  ? 'btn contentBorder'
                  : 'btn contentBorder enType'
                  "></div>
                <div @click="copy(item)" :style="item.renderStatus != '2' ? 'color:#D0D1D1' : ''" :class="$store.state.index.languageType == 'zh'
                  ? 'btn'
                  : 'btn enType'
                  ">
                  {{ $t("historyList.txt18") }}
                </div>
                <div :class="$store.state.index.languageType == 'zh'
                  ? 'btn contentBorder'
                  : 'btn contentBorder enType'
                  "></div>
                <div @click="download(item)" :style="item.renderStatus != '2' ? 'color:#D0D1D1' : ''" :class="$store.state.index.languageType == 'zh'
                  ? 'btn'
                  : 'btn enType'
                  ">
                  {{ $t("historyList.txt19") }}
                </div>
                <div :class="$store.state.index.languageType == 'zh'
                  ? 'btn contentBorder'
                  : 'btn contentBorder enType'
                  "></div>
                <el-dropdown :hide-on-click="false" @visible-change="getChange($event, item)">
                  <span class="el-dropdown-link">
                    {{ $t("historyList.txt17")
                    }}<i :class="showDropdownID == item.draperyId
    ? 'el-icon-arrow-up el-icon--right'
    : 'el-icon-arrow-down el-icon--right'
  "></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="deleteRecord(index, item)"><img class="deleteImg"
                        src="@/images/shanchu.png" alt="" /><span>{{
                          $t("historyList.txt20")
                        }}</span></el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-pagination @size-change="handleSizeChange" @current-change="changePage" :prev-text="$t('historyList.txt94')"
        :next-text="$t('historyList.txt95')" :current-page="page" :page-size="limit" style="text-align: center"
        layout="prev, pager, next" :total="total">
      </el-pagination>
    </div>
    <el-dialog :visible.sync="clothVisible" class="clothMap">
      <div class="img">
        <el-image style="width:98%;height:98%" :src="clothPath" fit="contain"></el-image>
      </div>
    </el-dialog>
    <exportDialog :dialogVisible="isShowExport" @cancelDialog="cancelExport" @confirmDialog="confirmExport" />
    <CommonDialog :dialogVisible="isShowDialog" :content="setContent" :confirmContent="setConfirmContent"
      @cancelDialog="cancelDialog" @confirmDialog="confirmDialog"></CommonDialog>
  </div>
</template>

<script>
import * as THREE from "three";
import {
  searchHistory,
  delHistory,
  getRenderStatus,
} from "@/api/history/history";
import CommonDialog from "@/components/commonDialog/CommonDialog.vue";
import exportDialog from "@/components/exportDialog/exportDialog.vue";
export default {
  name: "HistoryList",
  components: {
    CommonDialog,
    exportDialog,
  },
  data() {
    return {
      tableData: [],
      page: 1,
      limit: 20,
      total: 1,
      input: "",
      dateValue: "",
      productName: "",
      productCode: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      clothVisible: false,
      clothPath: "",
      clothWidth: 1,
      lastTime: "",
      productNameInputFocusType: false,
      timeInputFocusType: false,
      tableHead: [
        this.$t("historyList.txt7"),
        this.$t("historyList.txt8"),
        this.$t("historyList.txt9"),
        this.$t("historyList.txt10"),
        this.$t("historyList.txt11"),
        this.$t("historyList.txt12"),
        this.$t("historyList.txt13"),
        // this.$t("historyList.txt14"),
        // this.$t("historyList.txt15"),
      ],
      showDropdownID: "",
      isShowDialog: false,
      copyMachineId: "",
      copyDraperyId: "",
      setContent: "",
      setConfirmContent: "",
      currentDialogTitle: "",
      deleteDraperyId: "",
      isShowExport: false,
      exportId: "",
      bmpUrl: "",
      httpt: "",
    };
  },
  watch: {
    //切换复选框内容中英文
    "$store.state.index.languageType"(newVal, Val) {
      this.tableHead.forEach((element, index) => {
        this.$set(
          this.tableHead,
          index,
          this.$t(`historyList.txt${index + 7}`)
        );
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.httpt);
  },
  methods: {
    //刷新接口数据
    RenderStatus() {
      let data = [];
      this.tableData.forEach((item, index) => {
        if (item.renderStatus == 1) {
          data.push({
            designId: item.designId,
            page: this.page,
          });
        }
      });
      if (data.length != 0) {
        getRenderStatus({ list: data }).then((res) => {
          if (res.code == 0) {
            if (res.data[0].page == this.page) {
              res.data.forEach((item, index) => {
                this.tableData.forEach((el, i) => {
                  if (item.designId == el.designId) {
                    if (item.renderStatus != 1) {
                      this.$set(
                        this.tableData[i],
                        "renderStatus",
                        item.renderStatus
                      );
                      if (item.renderStatus == 2) {
                        this.$set(
                          this.tableData[i],
                          "pictureList",
                          item.designResultPath
                        );
                      }
                    }
                  }
                });
              });
            }
            let num = 0;
            this.tableData.forEach((item) => {
              if (item.renderStatus == 1) {
                num++;
              }
            });
            if (num == 0) {
              clearInterval(this.httpt);
            }
          }
        });
      } else {
        clearInterval(this.httpt);
      }
    },
    refreshListClick() {
      let that = this;
      // 设置定时器
      let timer;
      // 设置现在的时间
      let now = +new Date();
      // 当现在的时间减去上次点击的时间小于3s时,给出提示，并结束执行函数
      if (that.lastTime && now - that.lastTime < 2000) {
        clearTimeout(timer);
        return;
      } else {
        // 若大于3s，则把现在的时间赋值个lastTime
        that.lastTime = now;
      }
      timer = setTimeout(function () {
        that.getHistory();
        // 每次点击按钮都要把最新的时间赋值给lastTime
        that.lastTime = +new Date();
      }, 200);
    },
    columnStyle({ row, column, rowIndex, columnIndex }) {
      return "font-size:14px";
    },
    //查看详情
    lookDetail(row) {
      if (row.renderStatus == "2") {
        this.$router.push({
          path: `/history/thhistoryDetail/${row.draperyId}`,
        });
      } else {
        /*  delHistory({ draperyId: row.draperyId }).then((res) => {
          if (res.code == 0) {
            this.getHistory();
            this.$messagePup("删除成功", "success");
          } else {
            this.$messagePup(res.msg);
          }
        }); */
      }
    },
    //表头样式
    headerClass() {
      return "background: #f5f7fa;font-family: PingFangSC-Medium,PingFang SC;font-weight: 500;color: #000000; font-size: 16px;  border-right: none;";
    },
    //获取布料历史
    async getHistory() {
      clearInterval(this.httpt);
      const { dateValue } = this;
      let options = {
        pageNo: this.page,
        pageSize: this.limit,
        productName: this.productName ? this.productName : null,
        startTime: dateValue ? `${dateValue} 00:00:00` : null,
        endTime: dateValue ? `${dateValue} 23:59:59` : null,
      };
      const { code, data } = await searchHistory(options);
      if (code === 0) {
        this.total = data.page.totalCount;
        this.tableData = data.page.list;
        data.page.list.forEach((item, index) => {
          if (item.machineId == "deca2d3c072f5dbeedf29360785869ed") {
            data.page.list[index].NeedleType = 2;
          } else if (item.machineId == "fa50b8b616463173474302ca3e63586b") {
            data.page.list[index].NeedleType = 6;
          } else if (item.machineId == "49b5ddf0b626f2885df40efc8959e475") {
            data.page.list[index].NeedleType = 3;
          } else {
            data.page.list[index].NeedleType = 4;
          }
        });
        this.httpt = setInterval(() => {
          this.RenderStatus();
        }, 10000);
      }
    },
    handleSizeChange(val) { },
    changePage(val) {
      this.page = val;
      this.getHistory();
    },
    //品号
    productCodeChange(value) {
      this.productCode = value;
    },
    //品名
    productNameChange(value) {
      this.productName = value;
    },
    datapickerChange(value) {
      this.dateValue = value;
    },
    deleteRecord(index, value) {
      this.isShowDialog = true;
      this.setContent = this.$t("historyDetail.txt48");
      this.setConfirmContent = this.$t("historyDetail.txt49");
      this.currentDialogTitle = "delete";
      this.deleteDraperyId = value.draperyId;
    },
    copy(row) {
      if (row.renderStatus != "2") {
        return;
      }
      this.isShowDialog = true;
      this.setContent = this.$t("historyDetail.txt46");
      this.setConfirmContent = this.$t("historyDetail.txt47");
      this.currentDialogTitle = "copy";
      this.copyMachineId = row.machineId;
      this.copyDraperyId = row.designId;
    },
    cancelDialog() {
      this.isShowDialog = false;
      this.setContent = "";
      this.setConfirmContent = "";
      this.currentDialogTitle = "";
      this.copyMachineId = "";
      this.copyDraperyId = "";
    },
    confirmDialog() {
      if (this.currentDialogTitle == "copy") {
        let machineList = JSON.parse(localStorage.getItem("machineList"));
        machineList.forEach((item, index) => {
          if (item.machineId == this.copyMachineId) {
            this.copyMachineType = index;
            localStorage.setItem("machineType", JSON.stringify(item));
          }
        });
        this.$router.push({
          path: "/oneIndex",
          query: {
            machineType: this.copyMachineType,
            id: this.copyDraperyId,
          },
        });
      } else if (this.currentDialogTitle == "delete") {
        delHistory({ draperyId: this.deleteDraperyId }).then((res) => {
          if (res.code == 0) {
            this.getHistory();
            this.$messagePup(this.$t("historyDetail.txt50"), "success");
          } else {
            this.$messagePup(res.msg);
          }
        });
      } else {
      }
      this.isShowDialog = false;
    },
    download(val) {
      if (val.renderStatus != "2") {
        return;
      }
      this.exportId = val.draperyId;
      this.bmpUrl = val.pictureList.operationBmp;
      this.isShowExport = true;
    },
    cancelExport(val) {
      this.isShowExport = val;
    },
    confirmExport(val, selectType) {
      this.isShowExport = val;
      /* if (selectType == 1) {
        this.$router.push({
          path: `/downloadpage/?id=${this.exportId}&type=1`,
        });
      } else if (selectType == 2) {
        this.$router.push({
          path: `/downloadpage/?id=${this.exportId}&type=2`,
        });
      } */
      if (selectType == 1) {
        // this.showType = false;
        this.$router.push({
          path: `/history/thdownloadpage/?id=${this.exportId}&type=1`,
        });
      } else if (selectType == 2) {
        this.$router.push({
          path: `/history/thdownloadpage/?id=${this.exportId}&type=2`,
        });
      } else {
        if (this.bmpUrl) {
          this.showType = false;
          window.open(this.bmpUrl, "_blank");

          this.t = setTimeout(() => {
            this.showType = true;
            this.IsShowExport = false;
          }, 3000);
        } else {
          this.$message.warning(this.$t("historyDetail.txt51"));
          this.IsShowExport = false;
        }
      }
    },
    reset() {
      this.productName = "";
      this.productCode = "";
      this.dateValue = "";
      this.page = 1;
      this.getHistory();
    },
    async search() {
      const { productName, dateValue, productCode } = this;
      let options = {
        pageNo: 1,
        pageSize: this.limit,
        productName: productName ? productName : null,
        productCode: productCode ? productCode : null,
        startTime: dateValue ? `${dateValue} 00:00:00` : null,
        endTime: dateValue ? `${dateValue} 23:59:59` : null,
      };
      const res = await searchHistory(options);

      const { code, data } = res;
      if (code === 0) {
        this.total = data.page.totalCount;
        this.tableData = data.page.list;
      }
    },
    LookPic(row) {
      this.clothPath = row.pictureList.colorFront;
      let loader = new THREE.TextureLoader();
      let that = this;
      loader.load(this.clothPath, function (tex) {
        let color = tex;
        if (color.image.width > color.image.height) {
          that.clothWidth = (color.image.height / color.image.width) * 1;
        } else {
          that.clothWidth = (color.image.width / color.image.height) * 1;
        }
      });
      this.clothVisible = true;
    },
    getFrontUrlMap() {
      return {
        // "background-image": 'url("' + this.clothPath + '")',
        // width: "100%",
        // height: "98%",
        // "background-size": this.clothWidth * 100 + "%",
      };
    },
    getChange(e, val) {
      if (e) {
        this.showDropdownID = val.draperyId;
      } else {
        this.showDropdownID = -1;
      }
    },
  },
  computed: {},
  mounted() {
    this.getHistory();
    // var col = document.getElementsByTagName("col");
    // col.gutter.style.width = "0";
    // this.httpt = setInterval(() => {
    //   this.RenderStatus();
    // }, 10000);
  },
};
</script>

<style scoped lang="less">
@import "./HistoryList.less";
</style>

<style lang="less">
.tabletr {
  background: #f5f7fa !important;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500 !important;
  color: #000000 !important;
  font-size: 16px;
  border-right: none;
}
</style>